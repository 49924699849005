import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { CartId, ICart, ICartCheckout } from '@contexts/cart/domain/cart.model'
import type { CartDataSource } from '@contexts/cart/infrastructure/data-sources/cart.data-source'
import type { IFindOneCartQuery } from '@contexts/cart/domain/find-one-cart-query.interface'
import type {
  AddLineItemQueryV2,
  CartWithErrors,
  CartWithErrorsV2,
  UpdateLineItemOfferQuery
} from '@invivodf/module-cart-retail-interfaces'
import type { IUpdateLineQuantityQuery } from '@contexts/cart/domain/update-line-quantity-query.interface'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import type { CartDataSourceV2 } from '@contexts/cart/infrastructure/data-sources/v2/cart.data-source-v2'
import { UnprocessableCartError } from '@contexts/cart/infrastructure/unprocessable-cart-error'
import type {
  CartDiscountNotAppliedErrorSdk,
  CartDiscountNotFoundErrorSdk,
  UserNotAuthorizedErrorSdk,
  CartNotFoundErrorSdk,
  RetailBffSDK
} from '@invivodf/module-retail-bff-sdk'
import { FunctionalError as BffFunctionalError } from '@invivodf/module-retail-bff-sdk'
import { toICart, toICartWithErrors, toICartWithErrorsFromV2 } from './cart.mapper'

export class CartRepository implements ICartRepository {
  constructor(
    private cartDataSource: CartDataSource,
    private cartDataSourceV2: CartDataSourceV2,
    private retailBffSDK: RetailBffSDK
  ) {}

  async addLine(cartId: CartId | null, line: AddLineItemQueryV2): Promise<ICart> {
    const cartRaw = await this.cartDataSourceV2.addLine(cartId, line)
    return toICart(cartRaw.cart)
  }

  async findOne(params: IFindOneCartQuery): Promise<ICart> {
    const cartRaw = await this.cartDataSourceV2.findOne(params)

    return this.mapToCartRetail(cartRaw)
  }

  async removeLine(cartId: CartId, lineId: string): Promise<ICart> {
    const cartRaw = await this.cartDataSourceV2.removeLine(cartId, lineId)
    return this.mapToCartRetail(cartRaw)
  }

  async updateLineQuantity(queryParams: IUpdateLineQuantityQuery): Promise<ICart> {
    const cartRaw = await this.cartDataSourceV2.updateLineQuantity(queryParams)
    return this.mapToCartRetail(cartRaw)
  }

  async updateLineOffer(cartId: CartId, lineId: string, queryParams: UpdateLineItemOfferQuery): Promise<ICart> {
    const cartRaw = await this.cartDataSourceV2.updateLineOffer(cartId, lineId, queryParams)
    return this.mapToCartRetail(cartRaw)
  }

  async updateStore(store: ICurrentStore, cartId: string): Promise<ICart> {
    const cartRaw = await this.cartDataSourceV2.updateStore(store, cartId)
    return this.mapToCartRetail(cartRaw)
  }

  async createCheckout(cartId: CartId, storeId: ICurrentStore['id']): Promise<ICartCheckout> {
    return this.cartDataSourceV2.createCheckout(cartId, storeId)
  }

  async countLines(cartId?: string | null): Promise<number> {
    return this.cartDataSource.countLines(cartId).catch(() => 0)
  }

  async addPromoCode(
    cartId: CartId,
    promoCode: string
  ): Promise<
    | ICart
    | CartDiscountNotAppliedErrorSdk
    | CartDiscountNotFoundErrorSdk
    | UserNotAuthorizedErrorSdk
    | CartNotFoundErrorSdk
  > {
    const result = await this.retailBffSDK.addPromoCode({ cartId, promoCode })
    if (result instanceof BffFunctionalError) return result
    return this.mapToCartRetailFromV2(result)
  }

  async removePromoCode(
    cartId: CartId,
    promoCode: string
  ): Promise<ICart | UserNotAuthorizedErrorSdk | CartNotFoundErrorSdk> {
    const result = await this.retailBffSDK.removePromoCode({ cartId, promoCode })
    if (result instanceof BffFunctionalError) return result
    return this.mapToCartRetailFromV2(result)
  }

  private mapToCartRetailFromV2(cartRaw: CartWithErrorsV2): ICart {
    if (this.isCartWithErrors(cartRaw)) {
      const cartWithErrors = toICartWithErrorsFromV2(cartRaw)
      throw new UnprocessableCartError(cartWithErrors)
    }
    return toICart(cartRaw.cart)
  }

  private mapToCartRetail(cartRaw: CartWithErrors): ICart {
    if (this.isCartWithErrors(cartRaw)) {
      const cartWithErrors = toICartWithErrors(cartRaw)
      throw new UnprocessableCartError(cartWithErrors)
    }
    return toICart(cartRaw.cart)
  }

  private isCartWithErrors(cartRaw: CartWithErrors | CartWithErrorsV2): boolean {
    return (
      !!(cartRaw.warnings && cartRaw.warnings.length) ||
      !!(cartRaw.errors && cartRaw.errors.length) ||
      !!(cartRaw.alerts && cartRaw.alerts.length)
    )
  }
}
