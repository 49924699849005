import type { ICustomerProductReviewsRepository } from '@contexts/product/domain/product-reviews/product-reviews-repository.interface'
import type { ProductReviews } from '@/domain/product-reviews/product-reviews.model'

export class GetProductReviewsUsecase {
  constructor(public readonly customerProductReviewsRepository: ICustomerProductReviewsRepository) {}

  async execute(
    productId: string,
    offset: number | null,
    limit: number | null,
    sort: string | null,
    sortDirection: string | null
  ): Promise<ProductReviews | null> {
    return this.customerProductReviewsRepository.findProductReviews(productId, offset, limit, sort, sortDirection)
  }
}
