import type { ICurrentStore } from '@domain/current-store/current-store.interface'
import { OfferMapper } from '@contexts/offers/infrastructure/mappers/offer.mapper'
import { AlgoliaRecommendationMapper } from '@infrastructure/algolia/algolia-recommendation/data-sources/mappers/algolia-recommendation.mapper'
import type { IProductAvailability } from '@domain/product/product.interface'
import type { IAlgoliaRecommendationDTO } from '@infrastructure/algolia/algolia-recommendation/data-sources/algolia-recommendation.dto'
import type { ProductRecommendAvailability } from '@domain/product-recommend/product-recommend-availability.model'
import type { IRecommendationRepository } from '@domain/algolia-recommendation/recommendation-repository.interface'
import type { IAlgoliaRecommendationDataSource } from '@domain/algolia-recommendation/recommendation-datasource.interface'
import type { OfferDataSource } from '@infrastructure/offer'
import type { OfferPricing } from '@contexts/offers/infrastructure/offer-pricing.interface'

export class RecommendationRepository implements IRecommendationRepository {
  constructor(
    private recommendationDataSource: IAlgoliaRecommendationDataSource,
    private offerDataSource: OfferDataSource
  ) {}

  async getFrequentlyBoughtTogetherWithOffers(
    productIds: string[],
    currentStore: ICurrentStore | null,
    userId?: string
  ): Promise<ProductRecommendAvailability[] | null> {
    if (!productIds.length) return null
    let availabilities: IProductAvailability[] | null = null
    let offersPricing: OfferPricing[] = []
    const multipleResponse = await this.recommendationDataSource
      .getFrequentlyBoughtTogether<IAlgoliaRecommendationDTO>(productIds, currentStore)
      .catch(() => Promise.resolve(null))
    if (!multipleResponse?.results.length) return null
    const recommendations = AlgoliaRecommendationMapper.getRelevantRecommendations(multipleResponse, productIds)
    if (currentStore) {
      const offers = await Promise.all(
        recommendations.map(({ objectID }) =>
          this.offerDataSource.getBestOfferByProductIdAndStoreId(objectID, currentStore.id, userId)
        )
      )
      availabilities = offers.map(OfferMapper.extractAvailabilitiesFromOfferUnified)
      offersPricing = offers.map(OfferMapper.extractOfferPricing)
    }
    return AlgoliaRecommendationMapper.extractRecommendationAvailabilities(
      recommendations,
      availabilities,
      offersPricing,
      currentStore
    )
  }

  async getRelatedProductsWithOffers(
    productId: string,
    currentStore: ICurrentStore | null,
    userId?: string
  ): Promise<ProductRecommendAvailability[] | null> {
    if (!productId) return null
    let availabilities: IProductAvailability[] | null = []
    let offersPricing: OfferPricing[] = []
    const multipleResponse = await this.recommendationDataSource
      .getRelatedProducts<IAlgoliaRecommendationDTO>([productId], currentStore)
      .catch(() => Promise.resolve(null))
    if (!multipleResponse?.results.length) return null
    if (currentStore) {
      const offers = await Promise.all(
        multipleResponse.results[0].hits.map(({ objectID }) =>
          this.offerDataSource.getBestOfferByProductIdAndStoreId(objectID, currentStore.id, userId)
        )
      )
      availabilities = offers.map(OfferMapper.extractAvailabilitiesFromOfferUnified)
      offersPricing = offers.map(OfferMapper.extractOfferPricing)
    }

    return AlgoliaRecommendationMapper.extractRecommendationAvailabilities(
      multipleResponse.results[0].hits,
      availabilities,
      offersPricing,
      currentStore
    )
  }
}
