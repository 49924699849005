import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { CartId, ICart } from '@contexts/cart/domain/cart.model'
import type {
  CartDiscountNotAppliedErrorSdk,
  CartDiscountNotFoundErrorSdk,
  CartNotFoundErrorSdk,
  UserNotAuthorizedErrorSdk
} from '@invivodf/module-retail-bff-sdk'

export class AddPromoCode {
  constructor(private readonly cartRepository: ICartRepository) {}

  async execute(
    cartId: CartId,
    promoCode: string
  ): Promise<
    | ICart
    | CartDiscountNotAppliedErrorSdk
    | CartDiscountNotFoundErrorSdk
    | UserNotAuthorizedErrorSdk
    | CartNotFoundErrorSdk
  > {
    return this.cartRepository.addPromoCode(cartId, promoCode)
  }
}
