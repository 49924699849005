import type { IRequest } from '@application/composables/request'
import { ProductReviews } from '@domain/product-reviews/product-reviews.model'

export class ProductReviewsDataSource {
  constructor(public requestService: IRequest, public host: string | null) {}

  async fetchProductReviews(
    productId: string,
    offset: number | null,
    limit: number | null,
    sort: string | null,
    sortDirection: string | null
  ): Promise<ProductReviews | null> {
    const offsetLimitQuery = `?offset=${offset ?? 0}&limit=${limit ?? 5}`
    const sortQuery = sort ? `&sort=${sort}&sortdirection=${sortDirection}` : ''
    const url = `${this.host}/product/${productId}${offsetLimitQuery}${sortQuery}`
    return this.requestService
      .get<ProductReviews>(url)
      .then((data) => {
        if (!data) return null
        const { id, productName, reviewCount, scoreAverage, recommendationPercent, worstRating, bestRating, reviews } =
          data
        return new ProductReviews(
          id,
          productName,
          reviewCount,
          scoreAverage,
          recommendationPercent,
          worstRating,
          bestRating,
          reviews
        )
      })
      .catch(() => null)
  }

  async fetchLastBestProductReview(productId: string): Promise<ProductReviews | null> {
    const url = `${this.host}/product/${productId}?offset=0&limit=1&sort=score&sortdirection=desc`
    return this.requestService
      .get<ProductReviews>(url)
      .then((data) => {
        if (!data) return null
        const { id, productName, reviewCount, scoreAverage, recommendationPercent, worstRating, bestRating, reviews } =
          data
        return new ProductReviews(
          id,
          productName,
          reviewCount,
          scoreAverage,
          recommendationPercent,
          worstRating,
          bestRating,
          reviews
        )
      })
      .catch(() => null)
  }
}
