import type { ICartRepository } from '@contexts/cart/domain/cart-repository.interface'
import type { CartId, ICart } from '@contexts/cart/domain/cart.model'
import type { CartNotFoundErrorSdk, UserNotAuthorizedErrorSdk } from '@invivodf/module-retail-bff-sdk'

export class RemovePromoCode {
  constructor(private readonly cartRepository: ICartRepository) {}

  async execute(cartId: CartId, promoCode: string): Promise<ICart | UserNotAuthorizedErrorSdk | CartNotFoundErrorSdk> {
    return this.cartRepository.removePromoCode(cartId, promoCode)
  }
}
