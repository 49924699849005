export type ProductReviewFeedback = {
  createdAt: string
  author: string
  score: number
  recommendation: boolean
  comment: string
}

export class ProductReviews {
  constructor(
    public id: string,
    public productName: string,
    public reviewCount: number,
    public scoreAverage: number,
    public recommendationPercent: number,
    public worstRating: number,
    public bestRating: number,
    public reviews: ProductReviewFeedback[]
  ) {}

  isStoreReviews = false

  isProductReviews = true

  toJSON(): ProductReviews {
    return { ...this }
  }
}
