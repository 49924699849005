/* @todo: check global rules to fix. */

import type { LogisticDelay } from '@invivodf/module-logistic-offer-client-sdk'
import type { ProductDiscountScope } from '@domain/product/product.interface'

/* eslint-disable-next-line no-shadow */
export enum ProductSheetDeliveryMethod {
  LAD = 'LAD',
  CLICK_AND_COLLECT = 'C&C',
  STORE_DELIVERY = 'storeDelivery'
}

export interface ProductSheetDeliveryExpectation {
  earliestDeliveryDate: Date | null
  delay: LogisticDelay | null
  canDeliverByClickAndCollect: boolean
  canDeliverByShipping: boolean
  estimatedMinimalShippingCost?: number
}

export enum ProductSheetOfferDiscountUnit {
  PERCENTAGE = '%',
  EURO = '€'
}

export interface ProductSheetOfferDiscount {
  reference?: string
  amount: number
  discountedPrice: number
  originalPrice: number
  publicName?: string
  segment?: string
  unit: ProductSheetOfferDiscountUnit
  scope: ProductDiscountScope
  groupSize?: number
  validityEndDate?: string
}

export interface ProductSheetOfferSeller {
  id: string
  isMarketPlace: boolean
  name?: string
}

export class ProductSheetOffer {
  constructor(
    public productId: string,
    public reference: string,
    public sellerId: string,
    public supplierId: string,
    public displayedPrice: number,
    public stock: number,
    public deliveryMethod: ProductSheetDeliveryMethod,
    public seller: ProductSheetOfferSeller,
    public deliveryExpectation: ProductSheetDeliveryExpectation,
    public discount?: ProductSheetOfferDiscount
  ) {}
}

export interface DeliveryTypeOffers {
  clickAndCollect?: ProductSheetOffer
  shipping?: ProductSheetOffer
}
