import type { ICustomerProductReviewsRepository } from '@contexts/product/domain/product-reviews/product-reviews-repository.interface'
import type { ProductReviewsDataSource } from '@contexts/product/infrastructure/product-reviews/data-sources/product-reviews.data-source'
import type { ProductReviews } from '@/domain/product-reviews/product-reviews.model'

export class ProductReviewsRepository implements ICustomerProductReviewsRepository {
  constructor(private readonly customerProductReviewsDataSource: ProductReviewsDataSource) {}

  async findProductReviews(
    productId: string,
    offset: number | null,
    limit: number | null,
    sort: string | null,
    sortDirection: string | null
  ): Promise<ProductReviews | null> {
    return this.customerProductReviewsDataSource.fetchProductReviews(productId, offset, limit, sort, sortDirection)
  }

  findLastBestProductReview(productId: string): Promise<ProductReviews | null> {
    return this.customerProductReviewsDataSource.fetchLastBestProductReview(productId)
  }
}
