import type { ICart } from '@contexts/cart/domain/cart.model'
import type { OfferLogisticRoute } from '@infrastructure/offer'
// eslint-disable-next-line no-shadow
export enum CartErrorKeys {
  CART_MISSING_ERROR_KEY = 'cart.errors.missing',
  CART_RESTORE_ERROR_KEY = 'cart.errors.restore',
  STOCK_INSUFFICIENT_ERROR_KEY = 'insufficient.stock.errors',
  STOCK_MISSING_ERROR_KEY = 'stock.errors.missing',
  PRICE_CHANGED_ERROR_KEY = 'offer.price.errors',
  LOGISTIC_ROUTE_INVALID_ERROR_KEY = 'logistic.offers.errors.missing',
  DISCOUNT_ERRORS_AMOUNT = 'discount.errors.amount',
  DISCOUNT_ERRORS_MISSING = 'discount.errors.missing',
  OFFER_ERRORS_MISSING = 'offer.errors.missing',
  CHECKOUT_EXPIRED = 'cart.errors.checkout-expired',
  LINE_MISSING = 'lineItem.errors.missing',
  Unknown = 'error.unknown'
}

export type CartErrorsDetails = {
  id?: string
  productName?: string
  logisticRoute?: OfferLogisticRoute
  offerReference?: string
  lineItemId?: string
}

export type CartErrorData<T extends CartErrorKeys> = {
  key: T
  message: string
  details?: CartErrorsDetails
}

export type CartErrorModel<T extends CartErrorKeys> = {
  name: string
  message: string
  statusCode?: number
  data: CartErrorData<T>
}

export type CartWithErrorsData<T extends CartErrorKeys> = {
  alerts?: CartErrorData<T>[]
  warnings?: CartErrorData<T>[]
  errors?: CartErrorData<T>[]
  cart: ICart
}

export type CartWithErrors<T extends CartErrorKeys> = {
  name: string
  message: string
  statusCode?: number
  data: CartWithErrorsData<T>
}

export const isCartError = <T extends CartErrorKeys>(error: unknown, key: T): error is CartErrorModel<T> => {
  return (error as CartErrorModel<T>).data?.key === key
}

export const isCartWithErrors = (error: unknown): error is CartWithErrors<CartErrorKeys> => {
  const { data } = error as CartWithErrors<CartErrorKeys>
  return Array.isArray(data?.errors) || Array.isArray(data?.alerts) || (Array.isArray(data?.warnings) && !!data?.cart)
}
