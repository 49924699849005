import type { Hit, MultipleQueriesResponse } from '@algolia/client-search'
import type { IAlgoliaRecommendationDTO } from '@infrastructure/algolia/algolia-recommendation/data-sources/algolia-recommendation.dto'
import { ProductRecommend } from '@domain/product-recommend/product-recommend.model'
import type { ImageDataset } from '@application/types/image-dataset'
import { ProductRecommendAvailability } from '@domain/product-recommend/product-recommend-availability.model'
import type { ICurrentStore } from '@/domain/current-store/current-store.interface'
import type { IProductAvailability } from '@/domain/product/product.interface'
import type { OfferPricing } from '@contexts/offers/infrastructure/offer-pricing.interface'

type IHit<T> = Hit<T> & { _score: number }
export class AlgoliaRecommendationMapper {
  public static extractImage(description: string, url: string): ImageDataset | null {
    if (!description || !url) return null
    return {
      description,
      sources: [
        {
          url
        }
      ]
    } as ImageDataset
  }

  public static extract(
    multipleResponse: MultipleQueriesResponse<IAlgoliaRecommendationDTO> | null
  ): ProductRecommend[][] {
    if (!multipleResponse?.results?.length) return []
    return multipleResponse.results.map((searchResponse) => this.extractRecommendations(searchResponse.hits))
  }

  public static extractRecommendations(recommendations: Hit<IAlgoliaRecommendationDTO>[]): ProductRecommend[] {
    if (!recommendations?.length) return []
    return recommendations.map((hit) => {
      return new ProductRecommend(
        hit.objectID,
        hit.categories,
        this.extractImage(hit.name, hit.thumbnailUrl),
        true,
        hit.name,
        hit.indicativePrice,
        hit.slug,
        hit.brand
      )
    })
  }

  public static extractRecommendationAvailabilities(
    recommendations: Hit<IAlgoliaRecommendationDTO>[],
    availabilities: IProductAvailability[] | null,
    offersPricing: OfferPricing[],
    currentStore: ICurrentStore | null
  ): ProductRecommendAvailability[] {
    if (!recommendations?.length || !availabilities?.length) return []
    return recommendations.map((hit, index) => {
      const currentAvailability = availabilities[index]
      const offerPricing = offersPricing[index]
      const isIndicativePrice = !(currentStore && offerPricing?.price)
      const discount = offerPricing?.discount ?? undefined
      const price = currentStore && offerPricing ? offerPricing.price : hit.indicativePrice

      return new ProductRecommendAvailability(
        hit.objectID,
        hit.categories,
        this.extractImage(hit.name, hit.thumbnailUrl),
        isIndicativePrice,
        hit.name,
        price,
        hit.slug,
        currentAvailability,
        hit.isSellableOnline,
        hit.brand,
        discount
      )
    })
  }

  public static getRelevantRecommendations(
    multipleResponse: MultipleQueriesResponse<IAlgoliaRecommendationDTO> | null,
    productIds: string[]
  ): Hit<IAlgoliaRecommendationDTO>[] {
    const allHits: IHit<IAlgoliaRecommendationDTO>[] = multipleResponse?.results
      .map(({ hits }) => hits)
      .flat() as IHit<IAlgoliaRecommendationDTO>[]

    if (!allHits?.length) return []
    const filteredHits = allHits
      .filter((hit) => !productIds.includes(hit.objectID))
      // eslint-disable-next-line no-underscore-dangle
      .sort((a, b) => b._score - a._score)
      .slice(0, 8)
    if (!filteredHits.length) return []
    return filteredHits as Hit<IAlgoliaRecommendationDTO>[]
  }
}
