
// @ts-nocheck


export const localeCodes =  [
  "fr"
]

export const localeLoaders = {
  "fr": [{ key: "../src/application/locales/gammvert/fr.json", load: () => import("../src/application/locales/gammvert/fr.json" /* webpackChunkName: "locale__builds_invivoretail_enseignes_enseignes_com_src_application_locales_gammvert_fr_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../config/i18n/vueI18n.config.ts?hash=234086d5&config=1" /* webpackChunkName: "__config_i18n_vueI18n_config_ts_234086d5" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/i18n/vueI18n.config.ts",
  "locales": [
    {
      "code": "fr",
      "language": "fr-FR",
      "files": [
        "/builds/invivoretail/enseignes/enseignes.com/src/application/locales/gammvert/fr.json"
      ]
    }
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "purchase/delivery/index": {
      "fr": "/achat/livraison",
      "es": "/compra/entrega"
    },
    "purchase/payment/index": {
      "fr": "/achat/paiement",
      "es": "/compra/pago"
    },
    "stores/[slug]": {
      "fr": "/magasins/[slug]",
      "es": "/tiendas/[slug]"
    },
    "stores/index": {
      "fr": "/magasins",
      "es": "/tiendas"
    },
    "brands/index": {
      "fr": "/nos-marques",
      "es": "/nuestras-marcas"
    },
    "brands/[slug]": {
      "fr": "/marques/[slug]",
      "es": "/marcas/[slug]"
    },
    "my-account": {
      "fr": "/mon-compte",
      "es": "/mi-cuenta"
    },
    "my-account/my-addresses/index": {
      "fr": "/mon-compte/mes-adresses",
      "es": "/mi-cuenta/mis-direcciones"
    },
    "my-account/my-orders/index": {
      "fr": "/mon-compte/mes-commandes",
      "es": "/mi-cuenta/mis-ordenes"
    },
    "my-account/my-orders/[slug]": {
      "fr": "/mon-compte/mes-commandes/[slug]",
      "es": "/mi-cuenta/mis-ordenes/[slug]"
    },
    "my-account/my-information/index": {
      "fr": "/mon-compte/mes-informations",
      "es": "/mi-cuenta/mis-informaciones"
    },
    "my-account/my-store/index": {
      "fr": "/mon-compte/mon-magasin",
      "es": "/mi-cuenta/mi-tienda"
    },
    "my-account/my-loyalty/index": {
      "fr": "/mon-compte/mon-programme-fidelite",
      "es": "/mi-cuenta/mi-programa-de-fidelidad"
    },
    "cart/index": {
      "fr": "/panier",
      "es": "/carrito"
    },
    "order/confirmation/index": {
      "fr": "/commande/confirmation",
      "es": "/orden/confirmacion"
    },
    "promotions/index": {
      "fr": "/promotions",
      "es": "/promociones"
    },
    "search/index": {
      "fr": "/recherche",
      "es": "/busqueda"
    },
    "sellers/[slug]": {
      "fr": "/vendeurs/[slug]",
      "es": "/vendedores/[slug]"
    },
    "blog/index": {
      "fr": "/conseils-idees",
      "es": "/consejos-ideas"
    },
    "blog/[slug]": {
      "fr": "/conseils-idees/[slug]",
      "es": "/consejos-ideas/[slug]"
    },
    "blog/category/[category1]/index": {
      "fr": "/conseils-idees/categorie/[category1]",
      "es": "/consejos-ideas/categoria/[category1]"
    },
    "blog/category/[category1]/[category2]/index": {
      "fr": "/conseils-idees/categorie/[category1]/[category2]",
      "es": "/consejos-ideas/categoria/[category1]/[category2]"
    },
    "blog/category/[category1]/[category2]/[category3]/index": {
      "fr": "/conseils-idees/categorie/[category1]/[category2]/[category3]",
      "es": "/consejos-ideas/categoria/[category1]/[category2]/[category3]"
    },
    "sitemap/index": {
      "fr": "/plan-du-site",
      "es": "/plano-del-sitio"
    },
    "catalog/index": {
      "fr": "/catalogue",
      "es": "/catalogo"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "parsePages": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr",
    "language": "fr-FR",
    "files": [
      {
        "path": "/builds/invivoretail/enseignes/enseignes.com/src/application/locales/gammvert/fr.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
