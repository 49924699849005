import type { ICustomerProductReviewsRepository } from '@contexts/product/domain/product-reviews/product-reviews-repository.interface'
import type { ProductReviews } from '@/domain/product-reviews/product-reviews.model'

export class GetLastBestReview {
  constructor(public readonly customerProductReviewsRepository: ICustomerProductReviewsRepository) {}

  async execute(productId: string): Promise<ProductReviews | null> {
    return this.customerProductReviewsRepository.findLastBestProductReview(productId)
  }
}
