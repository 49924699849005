import type { IRequest } from '@application/composables/request'
import logger from '@utils/logger/logger'
import type { NavigationDTO } from '@/infrastructure/catalog/category/category-dto'

export class CategoryDataSource {
  constructor(public requestService: IRequest, public getCatalogApiUrl: (path?: string) => string) {}

  async fetchMainNavigation(locale: string): Promise<NavigationDTO | null> {
    try {
      const host = this.getCatalogApiUrl()

      const apiUrl = `${host}/tree-categories`
      return await this.requestService.get(`${apiUrl}?max-children=2`, {
        headers: {
          'Idf-Locale': locale
        }
      })
    } catch (e) {
      logger.error('Error while fetching main navigation', e)
      return null
    }
  }
}
