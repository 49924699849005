<template>
  <div v-if="typeof amount === 'number' && Boolean(currencyCode && languageCode)" class="ds-ens-pricing">
    <div class="ds-ens-pricing__body" :class="kind ? `ds-ens-pricing__body--${kind}` : ''">
      <span class="ds-ens-pricing__price" :style="{ 'justify-content': `flex-${align}` }">
        <span
          class="ds-ens-pricing__price-amount--xxl"
          :class="[
            priceSizeToken ? `ds-ens-pricing__price-amount--${priceSizeToken}` : '',
            {
              'ds-ens-pricing__price-amount--bold': isBoldPrice,
              'ds-ens-pricing__price-amount--with-discount': hasDiscount && discountScope === 'article'
            }
          ]"
        >
          {{ price }}
        </span>
        <span v-if="suffix" class="ds-ens-pricing__price-suffix">{{ suffix }}</span>
      </span>
      <span v-if="hasDiscount" class="ds-ens-pricing__discount">
        <span
          v-if="discountText"
          class="ds-ens-pricing__discount-text"
          :class="{ 'ds-ens-pricing__discount-text--crossed': isCrossedDiscount }"
          v-html="formattedDiscountText"
        >
        </span>
        <span v-if="formattedDiscount" class="ds-ens-pricing__discount-amount">
          {{ formattedDiscount }}
        </span>
      </span>
    </div>
    <div v-if="$slots.footer" class="ds-ens-pricing__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from '#imports'

import usePrice from '@application/composables/pricing/usePrice/usePrice'
import { formatPrice } from '@application/composables/pricing/usePrice/utils'
import { ProductDiscountScope } from '@domain/product/product.interface'

export default defineComponent({
  name: 'ds-ens-pricing',
  props: {
    amount: {
      type: Number,
      default: 0
    },
    currencyCode: {
      type: String,
      default: 'EUR'
    },
    discountAmount: {
      type: Number,
      default: 0
    },
    discountText: {
      type: [String, Number],
      default: null
    },
    discountUnit: {
      type: String,
      default: '%',
      validator: (value: string): boolean => ['€', '%'].includes(value)
    },
    discountScope: {
      type: String,
      default: ProductDiscountScope.ARTICLE,
      validator: (value: string): boolean => Object.values(ProductDiscountScope).includes(value as ProductDiscountScope)
    },
    isBoldPrice: {
      type: Boolean,
      default: true
    },
    isCrossedDiscount: {
      type: Boolean,
      default: false
    },
    kind: {
      type: String,
      default: 'vertical',
      validator: (value: string): boolean => ['horizontal', 'vertical'].includes(value)
    },
    align: {
      type: String,
      default: 'end',
      validator: (value: string): boolean => ['end', 'start'].includes(value)
    },
    languageCode: {
      type: String,
      default: 'fr-FR'
    },
    priceSizeToken: {
      type: String,
      default: 'xxxl',
      validator: (value: string): boolean => ['xxxl', 'xxl', 'xl', 'l', 'm'].includes(value)
    },
    suffix: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const { amount, currencyCode, discountAmount, discountUnit, discountText, languageCode } = toRefs(props)
    const { price, formattedDiscount } = usePrice(amount, discountAmount, discountUnit)

    const formattedDiscountText = computed<string>(() => {
      if (typeof discountText.value === 'number') {
        return formatPrice(discountText.value, languageCode.value, currencyCode.value)
      }
      return discountText.value
    })

    const hasDiscount = computed(() => Boolean(discountAmount.value || discountText.value))

    return { price, hasDiscount, formattedDiscountText, formattedDiscount }
  }
})
</script>

<style scoped lang="scss">
@import '@application/assets/css/common/mixins';

.ds-ens-pricing {
  display: flex;
  flex-direction: column;

  &__price-amount {
    &--bold {
      font-weight: 700;
    }

    &--xxxl {
      @include idf-font('xxxl');
    }

    &--xxl {
      @include idf-font('xxl');
    }

    &--xl {
      @include idf-font('xl');
    }

    &--l {
      @include idf-font('l');
    }

    &--m {
      @include idf-font('m');
    }

    &--with-discount {
      color: var(--idf-color-promo-400);
    }
  }

  &__price-suffix {
    color: var(--idf-color-neutral-700);
    vertical-align: super;

    @include idf-font('xs');
  }

  &__price {
    display: flex;
    gap: var(--idf-spacing-1);
  }

  &__discount-text {
    color: var(--idf-color-neutral-500);

    &--crossed {
      text-decoration: line-through;
    }

    @include idf-font('s');
  }

  &__discount-amount {
    @include ens-discount-tag();
  }

  &__discount {
    display: flex;
    gap: var(--idf-spacing-1);
    align-items: flex-end;
  }

  &__body {
    display: inline-flex;
    gap: var(--idf-spacing-1);
    width: fit-content;

    &--horizontal {
      flex-direction: row;
      align-items: center;
    }

    &--vertical {
      flex-direction: column;

      .ds-ens-pricing {
        &__price {
          order: 1;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    color: var(--idf-color-neutral-500);

    @include idf-font('s');
  }
}
</style>
