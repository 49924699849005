import { useConfig } from '@application/composables/config'
import { requestService } from '@application/composables/request'
import { OfferDataSource } from '@infrastructure/offer/offer.datasource'
import { CategoryDataSource } from '@infrastructure/catalog/category/category.datasource'
import { OffersClient } from '@invivoretail/module-offers-sdk'
import { CatalogClient } from '@invivoretail/module-catalog-sdk'
import type { CatalogClientConfig } from '@invivoretail/module-catalog-sdk'

const { getCatalogApiUrl, getRetailerCode, getEnv } = useConfig()
const config: CatalogClientConfig = {
  baseUrl: getEnv('CATALOG_API_HOST') ?? '',
  retailerCode: getRetailerCode(),
  options: {
    callerId: `enseignes-${getEnv('BRAND_NAME')}`
  }
}

export const catalogSdk = new CatalogClient(config)
export const categoryDataSource = new CategoryDataSource(requestService, getCatalogApiUrl)
export const offerDataSource = new OfferDataSource(new OffersClient({ baseUrl: getCatalogApiUrl() }))
