import type { MutationTree } from 'vuex'
import type { ICart } from '@contexts/cart/domain/cart.model'
import type { CartNotFoundErrorSdk, UserNotAuthorizedErrorSdk } from '@invivodf/module-retail-bff-sdk'
import { CartDiscountNotAppliedErrorSdk, CartDiscountNotFoundErrorSdk } from '@invivodf/module-retail-bff-sdk'
import { useNuxtApp } from '#imports'
import { cartTypes } from './constants'
import type { ICartState } from './interface'

const cartMutations: MutationTree<ICartState> = {
  [cartTypes.SET_CART]: (state: ICartState, cart: ICart) => {
    Object.assign(state, cart)
    if (!cart.promoCode) delete state.promoCode
  },
  [cartTypes.ADD_PROMO_CODE_ERROR]: (
    state: ICartState,
    promoCodeError:
      | CartDiscountNotAppliedErrorSdk
      | CartDiscountNotFoundErrorSdk
      | UserNotAuthorizedErrorSdk
      | CartNotFoundErrorSdk
  ) => {
    const { $i18n } = useNuxtApp()

    state.promoCodeMessage = { message: '', type: 'error' }
    if (promoCodeError instanceof CartDiscountNotAppliedErrorSdk) {
      state.promoCodeMessage.message = $i18n.t('cart.errors.promo-code-not-applied')
    } else if (promoCodeError instanceof CartDiscountNotFoundErrorSdk) {
      state.promoCodeMessage.message = $i18n.t('cart.errors.promo-code-not-found')
    } else {
      state.promoCodeMessage.message = $i18n.t('cart.errors.promo-code-unknown-error')
    }
  },
  [cartTypes.ADD_PROMO_CODE_SUCCESS_MESSAGE]: (state: ICartState) => {
    const { $i18n } = useNuxtApp()

    state.promoCodeMessage = { message: $i18n.t('cart.promo-code.success'), type: 'success' }
  },
  [cartTypes.REMOVE_PROMO_CODE_MESSAGE]: (state: ICartState) => {
    delete state.promoCodeMessage
  }
}

export default cartMutations
