import type { OffersClient } from '@invivoretail/module-offers-sdk'
import type { OfferUnifiedDTO } from './offers-api/offer-unified-dto'

export interface IOfferDataSource {
  getBestOfferByProductIdAndStoreId(
    productId: string,
    storeId: string,
    userId?: string
  ): Promise<OfferUnifiedDTO[] | null>
}

export class OfferDataSource implements IOfferDataSource {
  constructor(private readonly offersClient: OffersClient) {}

  /**
   * Get offers by productId and storeId
   * @param productId : string
   * @param storeId : string
   * @param {string=} userId
   * @returns : Promise<OfferDto[] | null>
   *
   */
  async getBestOfferByProductIdAndStoreId(
    productId: string,
    storeId: string,
    userId?: string
  ): Promise<OfferUnifiedDTO[] | null> {
    try {
      return await this.offersClient.getBestOffersByProductId(storeId, productId, userId || null)
    } catch {
      return null
    }
  }
}
