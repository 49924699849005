import { requestService } from '@application/composables/request'
import { useConfig } from '@application/composables/config'
import { GetProductReviewsUsecase } from '@contexts/product/use-cases/product-reviews/get-product-reviews.usecase'
import { ProductReviewsDataSource } from '@contexts/product/infrastructure/product-reviews/data-sources/product-reviews.data-source'
import { ProductReviewsRepository } from '@contexts/product/infrastructure/product-reviews/product-reviews.repository'
import { GetLastBestReview } from '@contexts/product/use-cases/product-reviews/get-last-best-review.usecase'

const { getEnv } = useConfig()
const host = getEnv('REVIEWS_API')

const customerProductReviewsDataSource = new ProductReviewsDataSource(requestService, host)
const customerProductReviewsRepository = new ProductReviewsRepository(customerProductReviewsDataSource)

const getProductReviews = new GetProductReviewsUsecase(customerProductReviewsRepository)
const getLastBestReview = new GetLastBestReview(customerProductReviewsRepository)

export { getProductReviews, getLastBestReview }
