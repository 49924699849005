import type { Auth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js'
import { REQUESTED_JARDILAND_SCOPES } from '../domain/constants/scopes.constants'
import type { IAuthenticationRepository } from '../domain/authentication-repository.interface'
import type { ILoginParamsDTO } from '../domain/login-dto.model'
import type { AccessToken } from '../domain/token.model'
import { mockIsAuthenticated, mockHandleRedirect, mockGetTokenSilently } from '../../../../tests/utils/auth0'

export class AuthenticationRepository implements IAuthenticationRepository {
  constructor(public getEnvironmentVariable: (path: string) => string | null, public getBaseUrl: () => string | null) {}

  async getClient(): Promise<Auth0Client | null> {
    if (process.client) {
      return import('@auth0/auth0-spa-js').then(({ Auth0Client }) => {
        return new Auth0Client({
          domain: this.getEnvironmentVariable('AUTH0_DOMAIN')!,
          client_id: this.getEnvironmentVariable('AUTH0_CLIENT_ID')!,
          redirect_uri: this.getEnvironmentVariable('AUTH0_REDIRECT_URI')!,
          audience: this.getEnvironmentVariable('AUTH0_AUDIENCE')!,
          cacheLocation: 'localstorage',
          advancedOptions: {
            defaultScope: 'openid'
          },
          scope: REQUESTED_JARDILAND_SCOPES.join(' ')
        })
      })
    }
    return null
  }

  async loginWithRedirect(params: ILoginParamsDTO): Promise<void> {
    const client = await this.getClient()
    return client?.loginWithRedirect(params)
  }

  async logout(logoutUrl: string): Promise<void> {
    const client = await this.getClient()
    const redirectUri = this.getBaseUrl() || this.getEnvironmentVariable('AUTH0_LOGOUT_URI')!
    return client?.logout({ returnTo: `${redirectUri}${logoutUrl}` })
  }

  async handleRedirect(): Promise<RedirectLoginResult<ILoginParamsDTO>> {
    if (mockIsAuthenticated()) return mockHandleRedirect()
    const client = await this.getClient()
    return (
      client?.handleRedirectCallback(window.location.href.replace('==', '%3D%3D')) ||
      Promise.resolve({ appState: { redirectUriAfterLogin: '' } } as RedirectLoginResult)
    )
  }

  async isAuthenticated(): Promise<boolean> {
    if (mockIsAuthenticated()) return mockIsAuthenticated()
    const client = await this.getClient()
    return client?.isAuthenticated() || false
  }

  async getAccessToken(): Promise<AccessToken> {
    if (mockIsAuthenticated()) return mockGetTokenSilently()
    const client = await this.getClient()
    return client?.getTokenSilently() || ''
  }

  async getAccessTokenIfAuthenticated(): Promise<AccessToken | null> {
    if (await this.isAuthenticated()) return this.getAccessToken()
    return null
  }

  async getAuthenticatedId(): Promise<string> {
    const client = await this.getClient()
    const userInformation = await client?.getUser()
    return userInformation?.sub || ''
  }
}
